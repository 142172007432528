import React from 'react';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/SideBar';
import ResumeFile from '../assets/Resume-BenjaminKauppi.pdf'


import zornCo from '../assets/images/ZRN-Logo-RED-FNL.png';
{/*import pic2 from '../assets/images/Sermons-com-Logo-1.jpg';
import pic3 from '../assets/images/about1.png';
import pic4 from '../assets/images/pic04.jpg';*/}
import thisSite from '../assets/images/website.png';
import natoChart from '../assets/images/nato-chart.png';
import skiing from '../assets/images/skiing.jpg';
import karate from '../assets/images/karate.jpg';
import magic from '../assets/images/magic.jpg';
import Scroll from '../components/Scroll';

const sections = [
  { id: 'top', name: 'Intro', icon: 'fa-home' },
  { id: 'portfolio', name: 'Portfolio', icon: 'fa-th' },
  { id: 'about', name: 'About Me', icon: 'fa-user' },
  { id: 'contact', name: 'Contact', icon: 'fa-envelope' },
];

const IndexPage = () => (
  <Layout>
    <SideBar sections={sections} />

    <div id="main">
      <section id="top" className="one dark cover">
        <div className="container">
          <header>
            <h2 className="alt">
              Hi! I'm <strong>Benjamin Kauppi</strong>
              <br />
              I am a {getAge("1992/08/05")} year old web developer
            </h2>
            <p>I made this website to host my <a href={ResumeFile} target="_blank" rel="noopener noreferrer">résumé</a> and <Scroll type="id" element={'portfolio'}><a href="#portfolio">portfolio</a></Scroll></p>
          </header>

          <footer>
            <Scroll type="id" element={'portfolio'}>
              <a href="#portfolio" className="button">
                Continue
              </a>
            </Scroll>
          </footer>
        </div>
      </section>

      <section id="portfolio" className="two">
        <div className="container">
          <header>
              <Scroll type="id" element={'portfolio'}>
                <a href="#portfolio">
                  <h2>Portfolio and Résumé</h2>
                </a>
              </Scroll>
            <p><a href={ResumeFile} target="_blank" rel="noopener noreferrer">My résumé can be found here</a></p>
          </header>

          <div className="row aln-center">
            <div className="col-4 col-10-mobile">
              <article className="item">
                <a href="#portfolio" className="image fit">
                  <img src={zornCo} alt="" />
                </a>
                <header>
                  <h3>Full Stack Developer</h3>
                  <h4>(2018-present):</h4>
                </header>
              </article>
            </div>
          </div>
          <h4>Current project:</h4>
          <p>
          <a href="https://sermons.com" target="_blank" rel="noopener noreferrer">Sermons.com</a>
          </p>
          <h4>past projects:</h4>
          <p>Creators Toy Chest (pending)</p>
          <p>Fourscore.IO (inactive)</p>
          <p>Pastor.com (inactive)</p>

          <h3>Personal Work:</h3>
          <div className="row aln-center">
            <div className="col-4 col-6-mobile">
              <article className="item">
                <a className="image fit">
                  <img src={thisSite} alt="" />
                </a>
                <header>
                  <h3><a href="#">This Website</a></h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-6-mobile">
              <article className="item">
                <a className="image fit">
                  <img src={natoChart} alt="" />
                </a>
                <header>
                  <h3>Python script to learn the <a href="https://en.wikipedia.org/wiki/NATO_phonetic_alphabet" target="_blank" rel="noopener noreferrer">NATO phonetic alphabet</a></h3>
                  <p><a href="nato.txt" target="_blank" rel="noopener noreferrer">source code</a> and <a href="NATO.zip" download>zip file</a></p>
                </header>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="three">
        <div className="container">
          <header>
            <Scroll type="id" element={'about'}>
              <a href="#about">
                <h2>About Me</h2>
              </a>
            </Scroll>
          </header>
          <p>
            In addition to programming I also enjoy:
          </p>
          <div className="row aln-center">
            <div className="col-4 col-10-mobile">
              <article className="item">
                <a className="image fit">
                  <img src={skiing} alt="" />
                </a>
                <header>
                  <h3>Skiing</h3>
                  <h4>Teaching and personal improvement</h4>
                </header>
              </article>
            </div>
            <div className="col-4 col-10-mobile">
              <article className="item">
                <a className="image fit">
                  <img src={karate} alt="" />
                </a>
                <header>
                  <h3>Martial Arts</h3>
                  <h4>Teaching and personal improvement</h4>
                </header>
              </article>
            </div>
            <div className="col-4 col-10-mobile">
              <article className="item">
                <a className="image fit">
                  <img src={magic} alt="" />
                </a>
                <header>
                  <h3>Magic the Gathering</h3>
                  <h4>MtG:Arena and playing with friends</h4>
                </header>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="four">
        <div className="container">
          <header>
            <Scroll type="id" element={'contact'}>
              <a href="#contact">
                <h2>Contact</h2>
              </a>
            </Scroll>
          </header>

          <p>
            <a className="h3 email" href="mailto:benjaminkauppi@gmail.com">BenjaminKauppi@gmail.com</a>
          </p>
        </div>
      </section>
    </div>

    <PageFooter />
  </Layout>
);

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
export default IndexPage;
